.login-card {
  width: 500px;
  margin: 7rem auto;
  padding: 16px 32px;
}

@media (max-width: 640px) {
  .login-card {
    width: 90%;
    margin: 5rem auto;
    padding: 16px 32px;
  }
}
